import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => {
    return (
        <Layout>
            <Seo
                title="Page Not Found!"
                description="Sorry the page you're looking for doesn't exist"
            />
            <div className="app-error">
                <div className="container">
                    <div className="grid" data-sal="slide-down">
                        <div className="col-lg-12 error-box">
                            <h1>404 - Page Not Found</h1>
                            <p>
                                Sorry, the page you're looking for doesn't
                                exist. <Link to="/">Return Home</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
